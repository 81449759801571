import React, { useEffect } from 'react'
import logger from 'logger'
import links from 'links'
import { useDevice } from 'device'

import { Button } from 'components/inputs'
import StatusCode from 'containers/StatusCode/StatusCode'
import PageError from 'compositions/PageError/PageError'

import messages from './messages'


const Page404 = () => {
  const { isMobile } = useDevice()

  useEffect(() => {
    logger.warn('Page not found')
  }, [])

  return (
    <div className="flex min-h-full w-full flex-col items-center justify-center bg-light-beige">
      <StatusCode value={404} />
      <PageError title={messages.title} text={messages.text} >
        <Button className={isMobile ? 'mt-40' : 'mt-32'} to={links.home} title={messages.buttonText} size={56} fullWidthOnMobile />
      </PageError>
    </div>
  )
}


export default Page404
